import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import useWindowSize from "../../utils/useWindowSize"
import Tabs from "../../components/common/Tabs"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"
import "./about-us-principal-styles.css"

const AboutUsPrincipalsPage = () => {
  const screen = useWindowSize()

  const prismicAboutUsPrincipal = useStaticQuery(graphql`
    query AboutUsPrincipalsQuery {
      prismicAboutUsPrincipals {
        data {
          hero_image {
            gatsbyImageData
          }
          title
          principal {
            name
            footer_info {
              richText
            }
            personal_info {
              richText
            }
            title
            pic {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const {
    hero_image,
    principal,
    title,
  } = prismicAboutUsPrincipal.prismicAboutUsPrincipals.data

  const image = getImage(hero_image)

  return (
    <Layout>
      <SEOnew title={title} />
      <div className="w-full bg-site-gray flex overflow-hidden relative lg:rounded-bl-100 lg:h-500 flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 h-full relative flex">
          <StaticImage
            id="cover"
            src="../../images/blank.png"
            quality={95}
            placeholder="DOMINANT_COLOR"
            formats={["AUTO", "WEBP"]}
            alt={`About${title}`}
            className="w-full h-full z-5 top-0 left-0"
            objectFit="cover"
            style={{position: "absolute"}}
          />
          <GatsbyImage
            image={image}
            alt={title}
            style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-transparent bg-toTopGray lg:bg-toRightGray">
            &nbsp;
          </div>
        </div>
        <div className="w-full lg:w-1/2 pt-12 lg:pt-24 pb-12 px-4 flex flex-col ">
          <div className="p-1 text-site-yellow flex flex-col text-base lg:text-2xl gap-y-4 px-2 md:px-4 h-full justify-center">
            <p>
              "Architecture should be an honest reflection of the owner’s goals
              and expectations while respectfully embracing and balancing the
              demands and benefits of the site. Architecture should enhance the
              quality of life for the client and the environment" - Brion
              Jeannette
            </p>
            <p>
              "Our goal is to create unique architecture in a responsible manner
              – not only for our clients, but also for our environment" - Amy J.
              Creager
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center flex-col pt-8 lg:pt-12 mb-12 overview-content">
        <div className="w-full max-w-7xl px-4">
          <Tabs active={1} />
        </div>
        <div className="w-full max-w-7xl px-4 py-8">
          {principal.map((principal, index) => {
            const profile_image = getImage(principal.pic)
            return (
              <div
                key={`principal-${index}`}
                className="flex flex-col border-b border-gray-400 mb-12"
              >
                <div className="about-us-principal-info flex flex-col lg:block justify-center items-center relative">
                  <div
                    className="relative"
                    style={{
                      width: "250px",
                      height: "275px",
                      float: "left",
                      outline: "#333333A0 solid 2px",
                      outlineOffset: "4px",
                      borderRadius: "100%",
                      marginRight: screen.width >= 1280 ? "50px" : "0px",
                      marginBottom: "32px",
                    }}
                  >
                    <StaticImage
                      id="cover"
                      src="../../images/blank.png"
                      quality={95}
                      placeholder="DOMINANT_COLOR"
                      formats={["AUTO", "WEBP"]}
                      alt={principal.name}
                      className="w-full h-full z-5 top-0 left-0"
                      objectFit="cover"
                      style={{position: "absolute"}}
                    />
                    <GatsbyImage
                      image={profile_image}
                      alt={principal.name}
                      style={{
                        width: "250px",
                        height: "275px",
                        float: "left",
                        outline: "#333333A0 solid 2px",
                        outlineOffset: "4px",
                        borderRadius: "100%",
                        marginRight: screen.width >= 1280 ? "50px" : "0px",
                        marginBottom: "32px",
                      }}
                    />
                  </div>
                  <div className="py-4">
                    <p className="text-site-yellow text-2xl font-medium">
                      {principal.name}
                    </p>
                    <p className="text-gray-500 text-xl mb-8">
                      {principal.title}
                    </p>
                    <PrismicRichText
                      field={principal.personal_info.richText}
                      components={htmlSerializer}
                    />
                  </div>
                </div>
                <div className="pt-4 about-us-principal-footer-info pb-16">
                  <PrismicRichText
                    field={principal.footer_info.richText}
                    components={htmlSerializer}
                    className="about-us-principal-footer-info"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default AboutUsPrincipalsPage
